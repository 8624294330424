import moment from "moment";

function numberFormat(number, delimiter = ' ') {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);
}

export default {
  lower: (s) => {
    if (typeof s !== 'string'){
      return ''
    }
    return s.toLowerCase();
  },
  zeroNumber: (number) => {
    if (String(number).length === 1) {
      return "0" + number;
    } else {
      return number;
    }
  },
  round: (s) => {
    return Math.floor(s);
  },
  pluralize: (count, words) => {
    let cases = [2, 0, 1, 1, 1, 2];
    let mod = count % 100;
    return words[(mod > 4 && mod < 20) ? 2 : cases[Math.min(count % 10, 5)]];
  },
  formatDate: (date) => {
    const frontFormat = moment(date, ['lll', 'YYYY-MM-DD HH:mm:ss']);
    return frontFormat.format('DD MMMM YYYY');
  },
  formatDateTime: (date) => {
    const frontFormat = moment(date, ['lll', 'YYYY-MM-DD HH:mm:ss']);
    return frontFormat.format('DD MMMM YYYY HH:mm');
  },
  formatDateTimeShort: (date) => {
    const frontFormat = moment(date, ['lll', 'YYYY-MM-DD HH:mm:ss']);
    return frontFormat.format('DD MMM YYYY в HH:mm');
  },
  dateFormatSpecial: (dateTime) => {
    const date = moment(dateTime, ['lll', 'YYYY-MM-DD HH:mm:ss']);
    return date.format('DD MMM');
  },
  dateFormatCalendar: (dateTime) => {
    const date = moment(dateTime, ['lll', 'YYYY-MM-DD HH:mm:ss']);
    return date.format('DD.MM.YYYY');
  },
  dateTimeFormatSpecial: (dateTime) => {
    const date = moment(dateTime, ['lll', 'YYYY-MM-DD HH:mm:ss']);
    if (date.hours() === 0 && date.minutes() === 0) {
      return date.format('DD MMM YYYY');
    } else {
      return date.format('DD MMM YYYY HH:mm');
    }
  },
  capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  },
  price(price) {
    const vals = String(price).split('.');
    const rubsFormatted = numberFormat(String(vals[0]).replace(/(.{3})$/g, (match) => ` ${match}`));
    if (vals.length === 1) {
      return `${rubsFormatted} ₽`;
    } else {
      return `${rubsFormatted}.${vals[1]} ₽`;
    }
  },
  numberFormat(price) {
    return numberFormat(Math.floor(price));
  },
  stsNumber(number) {
    return `${number.substr(0, 2)} ${number.substr(2, 2)} ${number.substr(4)}`;
  }
}
