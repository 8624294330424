<template>
  <template v-if="globalLoading">
    <loader-block />
  </template>
  <router-view v-else />
</template>
<script>

import { mapActions, mapMutations, mapState } from 'vuex';
import finesApi from '@rosfines/vue-common/common/api/finesApi';
import store from '@/store';
import LoaderBlock from '@/components/blocks/LoaderBlock.vue';

import '@rosfines/vue-common/common/services/webToNativeBridgeClient';

const GET = new URL(location.href).searchParams;
if (GET.get("userId") && GET.get("rand") && GET.get("session")) {
  const params = {
    response: {
      userId: GET.get("userId"),
      rand: GET.get("rand"),
      session: GET.get("session"),
    }
  };
  finesApi.updateRequestParams(params, false);
}
store.dispatch("auth", localStorage.getItem("source"));
if (GET.get("channel")) {
  const params = {
    response: {
      userId: GET.get("userId"),
      rand: GET.get("rand"),
      session: GET.get("session"),
    }
  };
  localStorage.setItem("channel", GET.get("channel"))
}
store.dispatch("init").catch(() => {
  alert('Ошибка загрузки, попробуйте позже');
});

export default {
  name: 'App',
  components: {
    LoaderBlock,
  },
  computed: {
    ...mapState({
      globalLoading: (state) => state.globalLoading,
    }),
  },
  mounted() {
    if (localStorage.getItem("channel") === 'AndroidReleaseGibdd') {
      document.body.setAttribute('data-color-scheme', 'blue');
    }
  },
  methods: {
    ...mapActions({
      loadFeaturesList: 'features/loadFeaturesList',
    }),
    ...mapMutations({
      setGlobalLoading: 'setGlobalLoading',
    })
  },
}
</script>
