import finesApi from '@rosfines/vue-common/common/api/finesApi';
import webToNativeBridgeClient from '@rosfines/vue-common/common/services/webToNativeBridgeClient';
import deviceStorage from '@/utils/deviceStorage';

export default {
  namespaced: true,
  state() {
    return {
      banksAllowed: [],
      banksInstalled: [],
    }
  },
  getters: {
    bankList (state) {
      return state.banksAllowed.filter(item => state.banksInstalled.includes(item.packageName));
    },
  },
  mutations: {
    setBanksAllowed(state, list) {
      state.banksAllowed = list;
    },
    setBanksInstalled(state, list) {
      state.banksInstalled = list;
    },
  },
  actions: {
    async getSBPPaymentLink(context, params) {
      return await finesApi.requestPromisePost('payment/sbp', params).catch(e => {});
    },
    loadSBP({dispatch}) {
      return Promise.all([
        dispatch('loadAllowedList'),
        dispatch('loadInstalledList')
      ])
    },
    async loadAllowedList({commit}) {
      const stored = deviceStorage.sbpBanks;
      if (stored) {
        commit('setBanksAllowed', stored);
      } else {
        const list = await finesApi.requestPromiseGet('payment/sbp/banks').catch(e => {});
        if (list && list.banks) {
          commit('setBanksAllowed', list.banks);
          deviceStorage.sbpBanks = list.banks;
        }
      }
    },
    async loadInstalledList({commit}) {
      const list = await webToNativeBridgeClient.getResult('action_get_sbp_banks').catch(e => {});
      commit('setBanksInstalled', list?.banks || []);
    },
  },
}