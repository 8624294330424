import * as VueRouter from 'vue-router';
import config from '@/config';

const FinishRegistration = () => import('@/pages/registration/FinishRegistration');

const router = VueRouter.createRouter({
  mode: 'history',
  history: VueRouter.createWebHistory(),
  base: '/',
  routes: [
    {
      path: '/registration', component: () => import('@/layout/Registration'), redirect: {name: 'addVehicle' },
      children: [
        { path: 'add-vehicle', component: () => import('@/pages/registration/AddVehicle'), name: 'addVehicle' },
        { path: 'add-sts', component: () => import('@/pages/registration/AddSts'), name: 'addSts' },
        { path: 'add-driver-license', component: () => import('@/pages/registration/AddDriverLicense'), name: 'addDriverLicense' },
        { path: 'finish-registration', component: FinishRegistration, name: 'finishRegistration' },
      ]
    },
    {
      path: '/add-doc', component: () => import('@/layout/AddDoc'), redirect: { name: 'addDocCar' },
      children: [
        { path: 'add-vehicle', component: () => import('@/components/blocks/registration/AddCarByGrz'), name: 'addDocCar' },
        { path: 'add-sts', component: () => import('@/components/blocks/registration/AddSts'), name: 'addDocSts' },
        { path: 'add-driver-license', component: () => import('@/components/blocks/registration/AddDriverLicense'), name: 'addDocDriverLicense' },
        { path: 'finish-registration', component: FinishRegistration, name: 'addDocFinish' },
      ]
    },


    { path: '/fine-list', component: () => import('@/pages/FineList'), name: 'fineList' },
    { path: '/fine', component: () => import('@/pages/FineItem'), name: 'fineItem' },

    { path: '/payment', component: () => import('@/pages/Payment'), name: 'payment' },

    { path: '/taxes/documents/add', component: () => import('@/pages/taxes/documents/Add'), name: 'taxesDocumentsAdd' },

    {path: '/:pathMatch(.*)*', redirect: {name: 'fineList'}}
  ]
});

router.afterEach(() => {
  window.ym && window.ym(config.YM_SITEKEY, 'hit');
});

router.rfNavigate = (path, params) => {
  if (window.WebToNativeBridge && window.WebToNativeBridge.update && path?.deeplink) {
    const deeplink = path?.matches ? path?.matches.reduce((res, match) => {
      return res.replace('${' + match + '}', params[match]);
    }, path.deeplink) : path?.deeplink;
    console.log(deeplink);
    location.href = deeplink;
  } else if (path?.route) {
    if (path.route === '_BACK_') {
      router.go(-1);
    } else {
      const query = path?.query
        ? path?.query.reduce((res, item) => {
          res[item] = params[item]
          return res;
        }, {})
        : undefined;
      console.log(query, params);
      router.push({
        name: path.route,
        query,
        params,
      })
    }
  }
};

export default router;
