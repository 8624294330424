import taxesApi from '@rosfines/vue-common/common/api/finesApi';

let gettingFinesList;
let gettingFinesListResolve;
let gettingFinesListReject;
let resetGettingFinesState = () => {
  gettingFinesList = new Promise((resolve, reject) => {
    gettingFinesListResolve = resolve;
    gettingFinesListReject = reject;
  });
};
resetGettingFinesState()

export default {
  namespaced: true,
  state () {
    return {
      ordinancesIds: [],
      chosenOrdinancesIds: [],
      ordinancesList: [],
    }
  },
  getters: {
    chosenTaxesIds(state) {
      return state.ordinancesIds.reduce((accumulator, id) => {
        accumulator[id] = state.ordinancesList.find((ordinance) => ordinance.id === id);
        return accumulator;
      }, []);
    },
  },
  mutations: {
    setTaxesList(state, taxesList) {
      state.ordinancesList = taxesList;
      for(const i in state.ordinancesList) {
        state.ordinancesList[i].id = parseInt(state.ordinancesList[i].id);
      }
      gettingFinesListResolve();
    },
    setAmountForOrdinances(state, data) {
      state.amountForOrdinancesResponse = data;
      if (state.totalOld === null) {
        state.totalOld = Math.floor(data.totalAmount / 100);
      }
    },
    setOrdinancesIds(state, ids) {
      const intIds = ids.map((id) => parseInt(id, 10));
      state.ordinancesIds = intIds;
      state.chosenOrdinancesIds = intIds;
    },
    toggleTax(state, id) {
      id = parseInt(id, 10);
      const index = state.chosenOrdinancesIds.indexOf(id);
      if (index === -1) {
        state.chosenOrdinancesIds.push(id);
      } else {
        delete state.chosenOrdinancesIds[index];
      }
    },
  },
  actions: {
    async waitForFinesList() {
      return gettingFinesList;
    },
    async getTaxesList({commit}, {
      channel = 'AndroidReleaseFines',
      type = 'all',
      ids = [],
    }) {
      // Список налогов, полученных с id-шек с query параметра
      let taxlist = []
      // Список всех налогов на аккаунте
      let allUserTaxes = [];

      // Получаем список налогов
      await taxesApi.getTaxesList({
        channel,
        type,
      }).then((taxesListsResponse) => {
        // Может приходить 2 варианта ответа
        if (taxesListsResponse?.taxes?.notpaid?.length > 0) {
          allUserTaxes = taxesListsResponse?.taxes?.notpaid?.map((ordinance) => {
            ordinance.amount = Number(ordinance.amount) * 100
            return ordinance
          })
        } else if (taxesListsResponse?.taxes?.length > 0) {
          allUserTaxes = taxesListsResponse.taxes
        }
        taxlist = allUserTaxes.filter((tax) => {
          return ids.includes(tax.id)
        }).map((tax) => {
          return {
            id: tax.id,
            description: tax.typeText,
            uin: tax.ordinanceNumber,
            fullAmount: tax.amount,
            amount: tax.amount,
            date: tax.createdTime,
            inn: tax.inn,
            details: tax.details
          }
        })
      }).catch(() => {
        gettingFinesListReject();
      });

      commit("setTaxesList", taxlist);
      return taxlist;
    },
    async getAmountForOrdinances ({state, rootState, commit, dispatch}) {
      await dispatch("waitForFinesList");
      const amountForOrdinancesResponse = await taxesApi.getAmountForOrdinances({
        type: 'tax',
        ids: state.chosenOrdinancesIds,
        useBonuses: rootState.bonuses.useBonuses,
        partialAmount: rootState.recurring.partialAmount,
      }).catch(e => {
        throw new Error(e);
      });
      if (amountForOrdinancesResponse) {
        commit("setAmountForOrdinances", amountForOrdinancesResponse);
      }
    },
    async toggleTax({dispatch, commit}, id) {
      commit("toggleTax", id);
      return await dispatch("getAmountForOrdinances", { type: 'tax' });
    },
  },
}